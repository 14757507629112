<template>
  <section class="containerNotificacao">
    <h1>{{titulo}}</h1>
    <p>{{mensagem}}</p>
    <button class="btnNotificacao" type="button"  @click="confirmarDelete($event)">Confirmar</button>
    <button class="btnNotificacao" type="button" @click="confirmarDelete($event)" >Cancelar</button>
  </section>
</template>

<script>
  export default {
    name: 'NotificacaoConfirmar',
    props: ['titulo', 'mensagem'],
    data() {
      return {

      }
    },

    methods: {
      confirmarDelete(event) {
        this.$emit('emit-click', event)
      }
    }
  }

</script>

<style scoped>
.containerNotificacao {
  background-color: white;
  box-shadow: 0px 0px 10px;
  padding: 10px;
  width: 400px;
  text-align: center;
  border-radius: 10px;
  -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.btnNotificacao {
    background-color: #1377f2;
    color: white;
    border-radius: 8px;
    padding: 2px;
    width: 100px;
    margin: 10px;
  }

  @keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


</style>