<template>
  <v-container class="v-container">
    <div class="header">
      <span>
        <h3>Cliente #{{id}}</h3>
        <p style="text-align: start;">Atualize um cliente para gerir os processos de implantação.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" v-if="loading" @click="$router.push('/lista/cliente')">voltar</button>
    </div>
    <form class="formulario" autocomplete='off' v-if="loading">
      
      <v-row>
        <v-col
            cols="12"
            sm="12"
          >
          <button class="btnMessage" style="width: 200px!important; margin-bottom: 30px;" @click="(e) => exibirContratos(e)">Exibir contratos</button>
          </v-col> 
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="nome"
            name="nome"
            label="Nome do cliente"
            :error-messages="erros.nome"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="cnpj"
            name="cnpj"
            label="Cnpj do cliente"
            :error-messages="erros.cnpj"
            v-mask="'##.###.###/####-##'"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="email1"
            name="email1"
            label="1º Email do cliente"
            :error-messages="erros.email1"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="telefone1"
            name="telefone1"
            label="1º Telefone do cliente"
            v-mask="'(##) # ####-####'"
            :error-messages="erros.telefone1"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="email2"
            name="email2"
            label="2º Email do cliente"
            :error-messages="erros.email2"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="telefone2"
            name="telefone2"
            label="2º Telefone do cliente"
            v-mask="'(##) # ####-####'"
            :error-messages="erros.telefone2"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >

          <v-select color v-model="flteste" item-text="nome" item-value="id" :items="ls_teste" filled return-object
          label="É Teste?"></v-select>
          
        </v-col>
      </v-row> 
      <div class="containerSistemasCadastrados">
      <div class="containerTituloButtonSistemasCadastrados">
        <p v-show="SistemasDoCliente.length === 0"> - Esse cliente não possui nenhum sistema cadastrado - </p>
        <p v-show="SistemasDoCliente.length > 0">Sistemas Cadastrados - </p>
        <button class="btnCadastrar" type="button" @click="cadastrarCliente">Cadastrar <img src="/AtualizarCliente/add.png" alt="icone adicionar" /></button>
      </div>     
      <ul>
        <li v-for="(Sistemas, index) in SistemasDoCliente" :key="index">{{Sistemas.nome_sistema}}<button type="button" class="buttonRemoverSistema" @click="armazenarSistemaDeletar(Sistemas)"><img src="/CriarCliente/iconeX.png" alt="img remove" /></button></li>
      </ul>
    </div>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="salvarCliente">
            atualizar cliente
          </v-btn>
        </v-col>  
    </form>
    <FormSkeleton v-if="!loading" />
    <div class="telaCadastroSistema" v-show="telaCadastroSistema">
      <div class="containerForm" v-show="containerCadastrodeSistemas">
        <section class="containerSelectButton"> 
          <v-select
            class="select"
            :items="sistemas"
            label="Selecione um sistema"
            v-model="sistemaSelecionado"
          ></v-select>

          <v-btn
            class="btnAddSistemas"
            color="#1377F2"
            type="button"
            @click="adicionarSistema"
            >
            Adicionar Sistemas
          </v-btn>
      </section>
          <p class="titleNenhumSistema" v-show="sistemasCadastrados.length == 0">Adicione os sistemas que deseja</p>
      <ul>
        <li v-for="(sistemas, index) in sistemasCadastrados" :key="index">{{sistemas}}<button type="button" class="buttonRemoverSistema" @click="removeSistemaDaLista(sistemas)"><img src="/CriarCliente/iconeX.png" alt="img remove" /></button></li>
      </ul>
      <v-btn
            class="btnFormaCadSistemasClientes"
            color="#1377F2"
            type="button"
            @click="finalizarCadastroDeSistemasContratados"
            >
            Finalizar cadastro
          </v-btn>
          <v-btn
            class="btnFormaCadSistemasClientes"
            color="#1377F2"
            type="button"
            @click="cancelarCadastroSistema"
            >
            Cancelar
          </v-btn>
      </div>
      <div v-show="notificacaoImplantacao" class="containerNotificacaoImplantacao">
        <h1>=)</h1>
        <h3>Sistemas cadastrados com sucesso!</h3>
        <p class="mensagemCriarImplantacao">Deseja criar implantações?</p>
        <div class="containerButtonNotificacaoImplant">
          <button type="button" class="btnMessage" @click="continuarCriarImplantacao($event)">Continuar</button>
          <button type="button" class="btnMessage" @click="continuarCriarImplantacao($event)">Finalizar</button>
        </div>
      </div>
      <FormCriarImplantacao  @emit-click="implantacaoConfirmar" v-show="showCriarimplementacao" 
      :idCliente="id" :sistemasDosCliente="sistemasParaImplantacao"
      />
    </div>
    <div class="containerNotificacaoConfirmar" v-show="containerNotificacaoShow">
      <FormAutenticacao v-show="formShowLogin" @emit-click="removeSistema" @emit-click-cancelar="cancelarAutenticacao"/>
      <NotificacaoConfirmar titulo="Atenção" mensagem="Deseja deletar esse sistema do cliente?" v-show="formShowConfirmar" @emit-click="confirmaDeleteSistema"/>
    </div>
  </v-container>

</template>

<script>
import {alertar} from '@/services/notificacao.service.js';
import FormAutenticacao from './FormAutenticacao.vue';
import NotificacaoConfirmar from './NotificacaoConfirmar.vue';
import ServicoCliente from '@/services/cliente.service.js';
import sistemasCliente from '@/services/sistemasCliente.service.js';
import validarCnpj from '@/util/validarCnpj.js';
import SistemaServico from '@/services/sistema.service.js';
import FormSkeleton from '@/components/Loading/FormSkeleton.vue';
import FormCriarImplantacao from './FormCriarImplantacao.vue';

export default {
  components: { FormSkeleton, FormAutenticacao, NotificacaoConfirmar, FormCriarImplantacao },
  data(){
    return {
      loading: false,
      nome: null,
      sistemas: [],
      ls_teste:[
        {id:0,nome:'Não'},
        {id:1,nome:'Sim'}
      ],
      flteste:{id:0,nome:'Não'},
      sistemaDeletar: null,
      telaCadastroSistema: false,
      sistemaSelecionado: null,
      formShowConfirmar: false,
      idSistemasCadastrados: null,
      formShowLogin: false,
      containerNotificacaoShow: false,
      containerCadastrodeSistemas: true,
      showCriarimplementacao: false,
      notificacaoImplantacao: false,
      cnpj: null,
      SistemasDoCliente: [],
      sistemasCadastrados: [],
      sistemasParaImplantacao: [],
      email1: null,
      telefone1: null,
      email2: null,
      telefone2: null,
      reqloading: false,
      id: null,
      erros: {
        nome: '',
        cnpj: '',
        email1: '',
        telefone1: '',
        email2: '',
        telefone2: '',
      }
    }
  },
  methods: {
    exibirContratos(e) {
      e.preventDefault()
      const { id } = this.$route.params
      localStorage.setItem('idCliente', id)
      this.$router.push({name: 'ListarContratosCliente' })
    },

    async salvarCliente(e){
      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
        let cliente = {
          cnpj: this.cnpj,
          id: this.id,
          nome: this.nome,
          telefonecontato1: this.telefone1,
          telefonecontato2: this.telefone2,
          contato1: this.email1,
          contato2: this.email2,
          teste:this.flteste?.id == 0?false:true
        }
        console.log('cliente ',cliente)
        await ServicoCliente.criar(cliente).then(res =>{
          if(res.status === 200){
            alertar('success', '=)', 'Cliente atualizado com sucesso!')
            this.reqloading = false
          }
        }).catch(e =>{
          this.reqloading = false
            alertar('warning', '=/', 'Falha na atualização do usuário!')
            console.log('e', e)
        })
      }
    },

    implantacaoConfirmar() {
      this.telaCadastroSistema = false,
      this.showCriarimplementacao = false;
      this.containerCadastrodeSistemas = false;
      this.sistemasParaImplantacao = [];
    },

    armazenarSistemaDeletar(sistema) {
      this.sistemaSelecionado = sistema;
      this.containerNotificacaoShow = true;
      this.formShowConfirmar = true;
    },

    confirmaDeleteSistema(payload) {
      const { innerText } = payload.target;
      if(innerText === 'Confirmar') {
        this.formShowConfirmar = false;
        this.formShowLogin = true;
      } else {
        this.formShowConfirmar = false;
        this.containerNotificacaoShow = false;
      }
      // formShowLogin
    },

    cancelarAutenticacao() {
        this.containerNotificacaoShow = false;
        this.formShowConfirmar = false;
        this.formShowLogin = false;
    },

    async removeSistema(payload) {
      console.log(this.sistemaSelecionado)
      if(payload === 'sucesso') {
        const {id} = this.sistemaSelecionado
        await sistemasCliente.deletar({id: id}).then(()=> {
        alertar('success', '=)', 'Sistema deletado com sucesso!')
        this.containerNotificacaoShow = false;
        this.formShowConfirmar = false;
        this.formShowLogin = false;
      })
      this.requisitarSistemasDoCliente()
      }
    },
  
    validacao(){
      let validado = []
      let objretorno;
      if(!this.nome || this.nome.trim() === ''){
        validado.push(false)
        this.erros.nome = 'O campo nome é obrigatório.'
      }
      if(!this.cnpj || this.cnpj.trim() === ''){
        validado.push(false)
        this.erros.cnpj = 'O campo cnpj é obrigatório.'
      }else{
        let cnpjvalido = validarCnpj(this.cnpj)
        validado.push(cnpjvalido)
        if(!cnpjvalido){
          this.erros.cnpj = 'Insira um cnpj válido.'
        }
      }
      if(!this.email1 || this.email1.trim() === ''){
        validado.push(false)
        this.erros.email1 = 'O campo email é obrigatório.'
      }
      if(!this.telefone1 || this.telefone1.trim() === ''){
        validado.push(false)
        this.erros.telefone1 = 'O campo telefone é obrigatório.'
      }
      if(!this.email2 || this.email2.trim() === ''){
        validado.push(false)
        this.erros.email2 = 'O campo email é obrigatório.'
      }
      if(!this.telefone2 || this.telefone2.trim() === ''){
        validado.push(false)
        this.erros.telefone2 = 'O campo telefone é obrigatório.'
      }
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    selecionarcampo(e){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = ''
      }
    },
    async requisitarCliente(){
      this.cliente = await ServicoCliente.buscar({id: this.id}).then(res=> res.data[0])
      console.log('busctou cliente')
      this.email1 = this.cliente.contato1
      this.cnpj = this.cliente.cnpj
      this.email2 = this.cliente.contato2
      this.telefone1 = this.cliente.telefonecontato1
      this.telefone2 = this.cliente.telefonecontato2
      this.nome = this.cliente.nome
      this.flteste = this.cliente.teste ? {id:1} :{id:0}
    },
    async requisitarSistemasDoCliente(){
      const buscaSistemas = await sistemasCliente.buscar({id: this.id}).then(res=> res)
      console.log('busctou sistema clientes')
      this.SistemasDoCliente = buscaSistemas.data;
    },

    async requisitarSistemas(){
      const buscaSistemas = await SistemaServico.buscar().then(res=> res.data)
      console.log('buscout sistemas')
      buscaSistemas.forEach((sistema) => {
        this.sistemas = [...this.sistemas, sistema.nome];
      });
      return buscaSistemas;
    },

    async adicionarSistema() {
      const sistemas = await this.requisitarSistemas()
      const verificacaoSistemasIguais = this.sistemasCadastrados.some((e) => e === this.sistemaSelecionado)
      if(verificacaoSistemasIguais) {
        return alertar('basic', 'Atenção!', 'Esse sistema já foi adicionado.')
      }
      if (this.sistemaSelecionado === null) return global.alert("Seleciona um sistema")

      const sistemaFilter = sistemas.filter((e) => e.nome == this.sistemaSelecionado);
      const { id, nome } = sistemaFilter[0];

      const objSistema = {
        id_sistema: id,
        nome_sistema: nome
      };
    
      this.sistemasCadastrados = [...this.sistemasCadastrados, this.sistemaSelecionado]
      this.sistemasParaImplantacao = [...this.sistemasParaImplantacao, objSistema]
    },

    removeSistemaDaLista(sistema) {
      this.sistemasCadastrados = this.sistemasCadastrados.filter((e) => e !== sistema);
    },

    async finalizarCadastroDeSistemasContratados() {
      const sistemas = await this.requisitarSistemas()
      let sistemasFilter = [];
      sistemas.forEach((e, i) => {
        sistemas.forEach((e2) => {
          if (this.sistemasCadastrados[i] === e2.nome) {
            sistemasFilter = [...sistemasFilter, e2]
          }
        })
      })
      let data = []
      sistemasFilter.forEach(async (e) => {
        data = [...data, {
          id_cliente: this.id,
          id_sistema: e.id
        }]
      })
      this.apiPostSistemaCliente(data)
    },

    async apiPostSistemaCliente(data) {

      await sistemasCliente.cadastrarSistemasClientes(data).then(res =>{
        console.log()
          if(res.status === 200){
            console.log(res);
            this.requisitarSistemasDoCliente()
            this.sistemasCadastrados = [];
            this.notificacaoImplantacao = true;
            this.containerCadastrodeSistemas = false;
          }
        }).catch(e =>{
          this.reqloading = false
            alertar('warning', '=/', 'Falha na crição do cadastro de sistemas!')
          console.log('e', e)
        })
    },

    async continuarCriarImplantacao(event) {
      const { target } = event;
      const { innerText } = target;

      if(innerText === 'Continuar') {
        await this.requisitarSistemasDoCliente()
        this.notificacaoImplantacao = false;
        this.showCriarimplementacao = true;
      } else {
        this.notificacaoImplantacao = false;
        this.telaCadastroSistema = false;
      }
 

    },

    cadastrarCliente() {
      this.telaCadastroSistema = true;
      this.containerCadastrodeSistemas = true;
    },

    cancelarCadastroSistema() {
      this.telaCadastroSistema = false;
    }

  },
  async mounted(){
    const {id} = this.$route.params
    this.id = id
    await this.requisitarCliente()
    await this.requisitarSistemasDoCliente()
    await this.requisitarSistemas()
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>

.v-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 93vh;
  width: 100vw;
}
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 20px;
}
.formulario{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px #d9d9d9;
  padding: 2em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
  .buttonRemoverSistema {
  cursor: pointer;
  background: none;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  }

  img {
    width: 20px;
  }

  ul {
    margin-top: 10px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 5px;
    width: 300px;
    border-top: 1px solid rgb(187, 187, 187);
  }

  .containerTituloButtonSistemasCadastrados {
    display: flex;
    align-items: center;
  }

  .btnCadastrar {
    border: 1px solid rgb(183, 183, 183);
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: 5px;
    border-radius: 5px;
    color: rgb(105, 105, 105);
    transition: 300ms;
  }
  .btnCadastrar:hover {
    background-color: #eaeaea;
    transition: 300ms;
  }

  img {
    margin-left: 5px;
    width: 20px;
  }

  .containerSistemasCadastrados {
    border: 1px solid rgb(156, 156, 156);
    padding: 10px;
    border-radius: 5px;
  }

  .telaCadastroSistema {
    background-color: rgba(32, 32, 32, 0.853);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 93vh;
  }

  .select {
  width: 50%  !important;
  margin-right: 10px;
  color: #808080;
  }

  .containerForm {
    background-color: white;
    width: 500px;
    min-height: 150px;
    padding: 20px;
    border-radius: 10px;
  }
  .btnAddSistemas {
  color:white;
  width: 200px;
  margin-left: 10px;
  }

  .containerSelectButton {
    display: flex;
    align-items: center;
  }

  .btnFormaCadSistemasClientes {
    margin-right: 10px;
    color: white;
    margin-top: 30px;
    width: 200px;
}


@keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.containerNotificacaoConfirmar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.603);
  position: absolute;
  width: 100vw;
  height: 93vh;
}

.containerNotificacaoImplantacao {
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  width: 500px;
  display: flex;
  flex-direction: column;
  -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.mensagemCriarImplantacao {
  margin-top: 10px;
}

.containerButtonNotificacaoImplant {
  margin-top: 20px;
}

.btnMessage {
  color: white;
  background-color: #1377F2;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  width: 100px;
}
</style>