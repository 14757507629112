<template>
  <section>
    <form>
      <h1>Confirmar Login</h1>
      <input class="inputText" type="text" placeholder="Email" v-model="email" />
      <input class="inputText" type="password" placeholder="Senha" v-model="senha" /> 
    <div class="containerButton">
      <button class="btnLogin" type="button" @click="autenticacaoLogin">Confirmar</button>
      <button class="btnLogin" type="button" @click="cancelarAutenticacaoLogin($event)">Cancelar</button>
    </div>
    </form>
    </section>
</template>

<script>
  import {alertar} from '@/services/notificacao.service.js';
  import AuthServico from '@/services/autenticacao.service.js'
    export default {
        name: "FormAutenticacao",
        data() {
            return {
                email: null,
                senha: null,
            }
        },
        methods: {

            async autenticacaoLogin() {
              if(this.validacao()){
                const dadosusuario = {
                  usuario: this.email,
                  senha: this.senha
                }
                await AuthServico.login(dadosusuario).then(res => {
                  if(res.status === 200){
                   this.$emit('emit-click', 'sucesso')
                  }
                }).catch(() => {
                  this.$emit('emit-click', 'error')
                  alertar('errorAutenticacaoLogin', 'Falha no login. Tente novamente.', '', 'warning')
                })
              }
            },

            cancelarAutenticacaoLogin(event) {
              this.$emit('emit-click-cancelar', event)
            },

            validacao() {
              let validado = true
              //valida senha
              if(!this.senha || this.senha.trim() === ''){
                validado = false
                this.erros.senha = 'O campo senha é obrigatório'
              } else if(this.senha.length < 6){
                validado = false
                this.erros.senha = 'A senha deve ser maior ou igual a 6 caracteres'
              }
              //valida email
              if(!this.email || this.email.trim() === ''){
                validado = false
                this.erros.email = 'O campo e-mail é obrigatório.'
              }
              if(!this.email.includes('@')){
                validado = false
                this.erros.email = 'Insira um e-mail válido.'
              }
            return validado
            }

        }
    }

</script>


<style scoped>
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 10px;
    width: 500px;
    padding: 10px;
    border-radius: 8px;
    -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  .inputText {
    border: 1px solid rgb(80, 80, 80);
    width: 90%;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .btnLogin {
    background-color: #1377f2;
    width: 50%;
    color: white;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
  }

  .containerButton {
    display: flex;
  }

  @keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


</style>